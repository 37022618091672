const getAllFromStore = async (storeName: string) => {
  const { openDB } = await import('idb');

  const db = await openDB('HomeflowDB', 1);

  try {
    const allRecords = await db.getAll(`${storeName}`);
    return allRecords;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default getAllFromStore;
