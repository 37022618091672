/* eslint-disable no-param-reassign */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import addToStore from '../../indexeddb/add-to-store';
import removeFromStore from '../../indexeddb/remove-from-store';

import Property from '../../api/models/property';

type PropertiesState = {
  results: InstanceType<typeof Property>[];
  pagination: {
    totalCount?: number;
    currentPage?: number;
    pageSize?: number;
    hasNextPage?: boolean;
  };
  savedProperties: number[]; // array of property IDs
};

const initialState: PropertiesState = {
  results: [],
  pagination: {},
  savedProperties: [],
};

export const addSavedProperty = createAsyncThunk(
  'properties/addSavedProperty',
  async ({ propertyId, local = true }: { propertyId: number; local?: boolean }) => {
    if (local) {
      await addToStore('saved_properties', propertyId, propertyId);
    }

    return propertyId;
  }
);

export const removeSavedProperty = createAsyncThunk(
  'properties/removeSavedProperty',
  async ({ propertyId, local = true }: { propertyId: number; local?: boolean }) => {
    if (local && propertyId) {
      await removeFromStore('saved_properties', propertyId);
    }

    return propertyId;
  }
);

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setPropertiesResults(state, action) {
      state.results = action.payload;
    },
    setPropertiesPagination(state, action) {
      state.pagination = action.payload;
    },
    setSavedProperties(state, action) {
      state.savedProperties = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addSavedProperty.fulfilled, (state, action) => {
      state.savedProperties.push(action.payload);
    });

    builder.addCase(removeSavedProperty.fulfilled, (state, action) => {
      const newSavedProperties = state.savedProperties.filter(
        (propertyId) => propertyId !== action.payload
      );

      return {
        ...state,
        savedProperties: newSavedProperties,
      };
    });
  },
});

export const {
  setPropertiesResults,
  setPropertiesPagination,
  setSavedProperties,
} = propertiesSlice.actions;

export const selectPropertiesResults = (state: { properties: PropertiesState }) =>
  state.properties.results;

export const selectPropertiesPagination = (state: { properties: PropertiesState }) =>
  state.properties.pagination;

export const selectSavedProperties = (state: { properties: PropertiesState }) =>
  state.properties.savedProperties;

export default propertiesSlice.reducer;
