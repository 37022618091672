const createLocalHFDB = async () => {
  const { openDB } = await import('idb');

  const db = await openDB('HomeflowDB', 1, {
    upgrade(db) {
      // Whenever you need a new store just add it here
      db.createObjectStore('saved_properties');
      db.createObjectStore('saved_searches');
      db.createObjectStore('search_history');
    },
  });

  return db;
};

export default createLocalHFDB;
