'use client';

import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import InitCompany from '@/app/init-company';
import InitUser from './init-user';
import { SerializableAgency } from '@/serializers/agency';
import HostCookieSetter from '@/components/dev/host-cookie-setter.component';

/**
 * Component to populate Redux store with data retrieved from server components in layout
 * also contains ToastContainer for notifications
 */
export default function InitState({
  agency,
  admin = false,
  theme,
}: {
  agency: SerializableAgency;
  admin?: boolean;
  theme?: string;
}): JSX.Element {
  useEffect(() => {
    if (!theme) return;
    // display app version and theme info in console
    console.log(
      `%cHomeflow %c[v4] %c[${theme}]`,
      'color: #FF6600; font-weight: bold;',
      'color: green; font-weight: bold;',
      'color: #3E5A81; font-weight: bold;'
    );
  }, [theme]);

  return (
    <>
      <InitCompany agency={agency} />

      {!admin && <InitUser />}

      <HostCookieSetter />
    </>
  );
}
