import snakify from './snakify';

export default function snakifyKeys(original: object) {
  const snakifiedObject = {};

  Object.keys(original).forEach((key) => {
    (snakifiedObject as any)[snakify(key)] = (original as any)[key];
  });

  return snakifiedObject;
}
