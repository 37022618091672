const removeFromStore = async (storeName: string, key: number | string) => {
  const { openDB } = await import('idb');

  const db = await openDB('HomeflowDB', 1);
  try {
    db.delete(`${storeName}`, key);
  } catch (e) {
    console.log(e);
  }
};

export default removeFromStore;
