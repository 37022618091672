import camelcaseKeys from 'camelcase-keys';
import { removeEmpties } from '../../utils';
import type { Search } from '../../search';
import { SavedSearch } from '../user';

export function searchesAreSame<T = SavedSearch>(s1: T, s2: T | Search) {
  // camelise to ensure searches are consistent and remove falsey values as sometimes a value is "" vs null
  const search1 = camelcaseKeys(removeEmpties(s1));
  const search2 = camelcaseKeys(removeEmpties(s2));

  if (search1.channel !== search2.channel) return false;
  if (search1.placeId !== search2.placeId) return false;
  if (search1.minBedrooms !== search2.minBedrooms) return false;
  if (search1.maxBedrooms !== search2.maxBedrooms) return false;
  if (search1.minPrice !== search2.minPrice) return false;
  if (search1.q !== search2.q) return false;
  if (search1.maxPrice !== search2.maxPrice) return false;
  if (search1.within !== search2.within) return false;
  if (search1.tags && search2.tags && search1.tags.length !== search2.tags.length) return false;
  if (
    search1.tags &&
    search2.tags &&
    !search1.tags.every((value: string) => search2.tags.includes(value))
  )
    return false;

  return true;
}

export default function findSavedSearchIndex(
  savedSearches: SavedSearch[],
  search: Search | SavedSearch
) {
  return savedSearches.findIndex((savedSearch) => searchesAreSame({ ...savedSearch }, search));
}
