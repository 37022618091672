/* Mutating params is how redux toolkit works */
/* eslint-disable no-param-reassign */
import camelcaseKeys from 'camelcase-keys';
import { v4 as uuid } from 'uuid';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import findSavedSearchIndex from './find-saved-search-index';

import { Search } from '../../search';
import addToStore from '../../indexeddb/add-to-store';
import { SavedSearch } from '../user';
import removeFromStore from '../../indexeddb/remove-from-store';
import { snakifyKeys } from '../../utils';

export type SearchState = {
  /**
   * initialSearch is the user's search immediately after it has been submitted and
   * does not change until a new search is run.
   */
  initialSearch: Search;
  savedSearches: SavedSearch[];
};

const defaultSearch = {
  channel: 'sales',
  q: '',
  tags: [],
  minPrice: null,
  maxPrice: null,
  minBedrooms: null,
  maxBedrooms: null,
  status: '',
};

export const initialState: SearchState = {
  initialSearch: defaultSearch,
  savedSearches: [],
};

export const addSavedSearch = createAsyncThunk(
  'search/addSavedSearch',
  async ({ search, local = true }: { search: SavedSearch; local?: boolean }) => {
    const key = uuid();
    if (local) {
      await addToStore('saved_searches', { ...search, key }, key);
    }

    return { ...search, key };
  }
);

export const removeSavedSearch = createAsyncThunk(
  'search/removeSavedSearch',
  async ({ search, local = true }: { search: SavedSearch; local?: boolean }) => {
    if (search.key && local) await removeFromStore('saved_searches', search.key);

    return search;
  }
);

// TODO: Implement when Searches API saved search updating is added
// export const updateSavedSearch = createAsyncThunk(
//   'search/updateSavedSearch',
//   async (search: Omit<SavedSearch, 'id'>) => {
//     // TODO: implement this when Searches API supports update

//     return search;
//   }
// );

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setInitialSearch(state, action) {
      state.initialSearch = action.payload;
    },
    setInitialSearchField(state, action) {
      state.initialSearch = {
        ...state.initialSearch,
        ...action.payload,
      };
    },
    setSavedSearches(state, action) {
      state.savedSearches = action.payload;
    },
    updateSavedSearch(state, action) {
      const { id } = action.payload;

      const index = state.savedSearches.findIndex((search) => search.id === id);

      if (index < 0) return;

      state.savedSearches[index] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addSavedSearch.fulfilled, (state, action) => {
      state.savedSearches.push(action.payload);
    });

    builder.addCase(removeSavedSearch.fulfilled, (state, action) => {
      const newSavedSearches = [...state.savedSearches];
      const search = camelcaseKeys(action.payload);

      // find the saved search
      const index = findSavedSearchIndex(newSavedSearches, snakifyKeys(search) as SavedSearch);

      if (index > -1) newSavedSearches.splice(index, 1);

      return {
        ...state,
        savedSearches: newSavedSearches,
      };
    });

    // TODO: Implement when Searches API saved search updating is added
    // builder.addCase(updateSavedSearch.fulfilled, (state, action) => {
    //   const index = findSavedSearchIndex(state.savedSearches, action.payload);
    //   if (index === -1) return state;

    //   const newSavedSearches = [...state.savedSearches];
    //   newSavedSearches[index] = action.payload;

    //   return {
    //     ...state,
    //     savedSearches: newSavedSearches,
    //   };
    // });
  },
});

export const { setInitialSearch, setInitialSearchField, setSavedSearches, updateSavedSearch } =
  searchSlice.actions;

/**
 * The initialSearch state represents the state of the search the last time it was run/submitted.
 * It should only be updated when a new search is run.
 */
export const selectInitialSearch = (state: { search: SearchState }) => state.search.initialSearch;

export const selectSavedSearches = (state: { search: SearchState }) => state.search.savedSearches;

export default searchSlice.reducer;
