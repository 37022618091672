const isEmpty = (inputValue: any): boolean => {
  if (!inputValue) return true;
  if (Array.isArray(inputValue) && inputValue.length === 0) return true;

  return false;
};

const removeEmpties = (inputObject: any): any => {
  if (!inputObject) return inputObject;
  if (Array.isArray(inputObject)) return inputObject;
  if (inputObject.constructor != Object) return inputObject;

  return Object.entries({ ...inputObject }).reduce(
    (accumulator, [key, value]) =>
      isEmpty(value) ? accumulator : { ...accumulator, [key]: removeEmpties(value) },
    {}
  );
};

export default removeEmpties;
