const addToStore = async (
  storeName: string,
  value: number | string | object | any,
  key: number | string
) => {
  const { openDB } = await import('idb');

  const db = await openDB('HomeflowDB', 1, {
    upgrade(db) {
      db.createObjectStore(`${storeName}`);
    },
  });

  try {
    await db.put(`${storeName}`, value, key);
  } catch (e) {
    console.log(e);
  }
};

export default addToStore;
